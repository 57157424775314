$gutter: 24px;
$gutter-os: 32px;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.1;
}

@media (min-width: 576px){
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px){
  .container {
    max-width: 744px;
  }
}

@media (min-width: 1024px){
  .container {
    max-width: 984px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 984px;
  }
}

@media (min-width: 1200px){
  .container {
    max-width: 1152px;
  }
}

@media (min-width: 1441px){
  .container {
    max-width: 1375px;
  }
}

.container, .container-xl  {
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

.row {
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;

  &.no-margin {
    margin-right: 0;
    margin-left: 0;
  }
}

[class^="col-"]{
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

@media (min-width: 1441px) {
  .container{
    max-width: $grid-max-width;
  }

  .modal-create-comparison{
    .modal-body {
      .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  .os-main-nav-inner {
    margin: 0px auto;
    padding: 0px 52px !important;
  }


  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9{
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 2560px) {
  .clv-page {
    .os-main-nav-inner {
      max-width: 67.5%;
    }
  }
  .container{
    &.lecture-preview {
      max-width: 67.5%;
    }
  }
}

.mobile-device {
  &.portrait {
    .container{
      &.container-mx-3 {
        padding-right: 15px;
        padding-left: 15px;

        .row {
          margin-right: -15px;
          margin-left: -15px;

          &.no-margin {
            margin-right: 0;
            margin-left: 0;
          }
        }

        [class^="col-"]{
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }

  &.landscape {
    .explore, .posts{
      .container {
        max-width: 576px;
      }
    }
  }
}

// 10 column grid

.col-os-1{
  @include col-os(1);
}

.col-os-2{
  @include col-os(2);
}

.col-os-3 {
  @include col-os(3);
}

.col-os-4{
  @include col-os(4);
}

.col-os-5{
  @include col-os(5);
}

.col-os-6{
  @include col-os(6);
}

.col-os-7{
  @include col-os(7);
  &.full-width {
    min-width: 100%;
    flex: 100%;
  }
}

.col-os-8 {
  @include col-os(8)
}

.col-os-9{
  @include col-os(9);
}

.col-os-10 {
  @include col-os(10);
}

[class^="col-os"] {
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
}

@media screen and (max-width: 767px) {
  [class^="col-os"] {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .xl-container {
    max-width: $grid-max-width;

    &.px-52 {
      padding-left: 12px;
      padding-right: 12px;
    }

    &.px-m-12 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

@media screen and (min-width: 1250px) and (max-width: 1439px){
  .xl-container {
    &.px-hd-40 {
      /*padding-left: 48px;
      padding-right: 48px;*/
    }
  }

  .head-sticky {
    .sub-nav-background {
      /*padding-left: 48px;
      padding-right: 48px;*/
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  .xl-container {
    &.px-sm-32 {
      padding-left: 24px;
      padding-right: 24px;
    }

    &.px-sm-0 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .head-sticky {
    .sub-nav-background {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

@media screen and (max-width: 767px) {
  .xl-container {
    &.px-m-12 {
      padding-left: 12px;
      padding-right: 12px;
    }

    &.px-m-0 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .head-sticky {
    .sub-nav-background {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.ipad {
  &.landscape {
    .xl-container {
      &.px-hd-40 {
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    .head-sticky {
      .sub-nav-background {
        padding-left: 48px;
        padding-right: 48px;
      }
    }
  }
}
