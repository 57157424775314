$gutter: 24px;
$grid-max-width: 1344px;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.1;
}

a {
  text-decoration: none;
}

.row > { 
  .osbtn {
    width: unset;
  }

  .activity-container,
  .conversation-card-container,
  .conversation-right-panel {
    @include gutterx(0);
  }
}

.media {
  display: flex;
  align-items: flex-start;
  
  .media-body {
    flex: 1;
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.table,
table {
  color: inherit;
  td {
    color: inherit;
  }
}
