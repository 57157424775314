.start-diagno-btn{
  padding: 12px 18px 0;
  font-size: 15px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width:100%;

  &.hide{
    display: none;
  }
}

.add-appointment-btn {
  background-color: $os-link;
  color: #fff;
  border-radius: 4px;
  display: inline-flex;
  font-weight: 600;

  .btn-icon-helper {
    left: auto !important;
    margin-left: -24px;
  }

  &.osbtn-secondary {
    color: $os-link;
    background-color: transparent;

    &:hover {
      border-color: $os-link-hover;
    }
  }
}

.cs-pataint-card {
  padding: 24px 16px;
  background-color: #222226;
  border-radius: 8px;
}

.patient-info-list {
  color: $level-12;
  font-size: 16px;
  line-height: 24px;

  &.gaurdian-list {
    margin-top: 24px;
  }

  li {
    position: relative;
    margin-bottom: 24px;
    color: $level-12;
    font-size: 16px;
    line-height: 24px;

    &.with-icon {
      padding-left: 24px;
      margin-bottom: 12px;
    }

    &.without-icon {
      margin-bottom: 0;
    }

    address {
      color: $level-9;
    }
  }

  .cs-pt-extra {
    color: $level-10;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding-bottom: 2px;
  }

  i {
    position: absolute;
    left: 0;
    top: 5px;
    color: $level-10;
    font-size: 16px;
  }

}


.ipad {
  &.portrait {
    .cs-pataint-card {
      margin: 0 24px 24px 24px;
    }
  }
}

.clv-row {
  .cs-id {
    @include fontstyle(12px, normal, 16px);
    color: $level-11;
    padding-right: 24px;

    &:hover {
      color: $level-11 !important;
      text-decoration: none !important;
    }
  }

  a {
   color: $level-9;
    &:hover {
      color: $os-link-hover;
    }
  }

  .cate + .detail-subheader {
    margin-left: 24px;
  }

  .detail-subheader {
    @include fontstyle(20px, 600, 16px);
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .indicator-badge {
    margin-left: 8px;
  }

  .cs-user-info {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    color: #e7e7ea;

    .patient-name {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }
  }

  .cs-id-user-name {
    display: inline-flex;
    align-items: center;

    .os-btn-link {
      .user-title {
        color: inherit !important;
      }
    }

    .detail-subheader {
      @include fontstyle(12px, normal, 16px);
    }
  }

  .user-title {
    font-size: 12px;
    color: $level-9;
  }

  .doc-name-case {
    display: inline-block !important;
    color: $level-9;
    font-size: 12px;
    line-height: 16px;

    &:hover {
      color: $os-link !important;
      text-decoration: underline;

      a, span {
        color: $os-link !important;
      }
    }

    > a, span {
      &:hover {
        color: $level-11;
      }
    }
  }

  &.new-cs-card {
    .submit-right-container {
      padding-top: 0;

    }
  }
}
