label {
  color: $level-11 !important;
}

.form-control,
.text-field {
  @include font-text($font-weight-normal, 16px, 1);
  height: 40px;
  box-shadow: none;
  border-radius: 8px;
  background: $input-bg;
  color: $input-text-color;
  padding: 12px 16px;
  border: 1px solid $input-border;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  filter: none;

  &[type="password"] {
    padding-right: 40px;
  }

  &:hover {
    border:1px solid  $input-hover-border;
  }

  &:active, &:focus {
    border:1px solid  $input-border-active;
    box-shadow: none;
    outline: none;
    caret-color: $level-10;
    box-shadow: $input-shadow;
    color: $input-text-color;
  }

  &.text-error {
    border: 1px solid $input-error;

    &:active, &:focus {
      border-color: $input-error;
    }
  }

  &.textarea-field {
    height: 86px;
  }

  &.w-100 {
    width: 100%;
  }

  &:disabled {
    color: $input-disabled-text;

    &:active, &:focus {
      border:transparent;
    }
    ::-webkit-input-placeholder {
      color: $input-disabled-text;
    }

    ::-moz-placeholder {
      color: $input-disabled-text;
    }

    :-ms-input-placeholder {
      color: $input-disabled-text;
    }

    :-moz-placeholder {
      color: $input-disabled-text;
    }
  }
  &[readonly] {
    pointer-events: none;
    color: $input-disabled-text !important;
  }
}


::-webkit-input-placeholder {
  color: $input-placeholder !important;
}

::-moz-placeholder {
  color: $input-placeholder !important;
}

:-ms-input-placeholder {
  color: $input-placeholder !important;
}

:-moz-placeholder {
  color: $input-placeholder !important;
}

.nav-search-holder {
  width: 270px;
  height: 40px;
  position: relative;

  &.space-search-container {
    width: 100%;
    margin-bottom: 24px
  }

  label {
    height: 100%;
  }
}

label {
  color: $level-11;
}

input[type='password'] {
  &:active, &:focus {
    color: $os-link;
  }
}

input{
  &:-webkit-autofill,
  &:hover, &:focus {
    border-color: $input-border;
  }

  caret-color : $caret;
}

.Select-control {
  background-color: #303036 !important;
}
.Select-placeholder {
  background-color: #303036;
}

.form-error {
  color: inherit;
  font-size: 12px;

  .ifill-os-error, .error-icon {
    color: $lt-red ;
  }
}


.Select-input > input {
  color: $level-11;
}


.os-post-message-block {
  .form-control {
    margin-bottom: 0;
  }
}

.pmb-conatiner {
  position: relative;

  .post-message-button {
    position: absolute;
    height: 100%;
    width: 48px;
    border-radius: 0 4px 4px 0;
    top: 0;
    right: 0;
    transition: background .5s ease;
    border: 0;
    outline: 0 !important;
    -webkit-appearance: none;
    color: $level-11;
    font-size: 22px;
    line-height: 38px;
    background: transparent;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .post-btn-helper-icon {
    position: absolute;
    left: 50%;
    top: 8px;
    transform: translateX(-50%);
  }

  .form-group  {
    margin-bottom: 0;
  }
}

.post-form-control {
  padding-right: 55px;
  resize: none;
  line-height: normal;
  padding-top: 9px;
  padding-bottom: 0;

  .post-message-button {
    background-color: transparent !important;
  }

  &:focus {
    + .post-message-button,
      .post-message-button:focus,
      .post-message-active {
        background: $cl-link;

        i {
          color: inherit;
        }
      }
  }

  &.error {
    + .post-message-button,
      .post-message-button:focus,
      .post-message-active {
        background-color: $lt-red !important;
        min-height: 32px;
        max-height: 100%;
        color: $level-11 !important;
        border-radius: 0 4px 4px 0;
        border-color: $lt-red !important;

        .post-btn-helper-icon {
          color: $level-1  !important;
        }
      }
  }

}


.no-touch-support {
  .post-form-control:not(.error):focus + {
    .post-message-button.osbtn:hover {
      background: $os-link-hover;
      border-color: $os-link-hover;
      color: $level-1;

      .btn-icon-helper {
        color: inherit !important;
      }
    }
  }
}


.mobile-device {
  .pmb-conatiner {
    direction: ltr;
  }

  .nav-search-holder {
    &.space-search-container {
      padding: 0px 20px;

      .ifill-os-search {
        font-size: 20px;
        left: 12px;
      }
    }
  }
}

.os__option--is-disabled {
  color: $dropdown-text !important;
  pointer-events: none;
  background-color: $dropdown-list-hover;
}


.no-touch-support {
  .number-filed-arrow-hide {
    .form-group  {
      &:before {
        content: '';
        position: absolute;
        right: 1px;
        top: 25px;
        bottom: 1px;
        width: 50px;
        background: #303036;
        z-index: 1;
        pointer-events: none;
        border-radius: 4px;
      }
    }
  }
}
