.os__clear-indicator {
  cursor: pointer !important;
}

.modal-xl {
  &.modal-share {
    width: 600px;
    margin-top: 0;
    position: relative;
    top: 10%;

    .ifill-os-close {
      cursor: pointer;
    }

    .share-modal-height {
      min-height: 170px;
    }


    .selection-mode {
      .cs-title {
        .no-patient-info {
          font-size: 13px;
          font-weight: normal;
        }
      }
    }

    .modal-body-share {
      padding-left: 24px;
      padding-right: 16px;

      @media(max-width: 768px) {
        padding-left: 16px;

        .share-error {
          padding-left: 10px;

          .ifill-os-fill-error {
            margin-right: 0.25rem !important;
          }
        }

        .share-modal-footer {
          left: 16px;
        }
      }

      .reply-buttons {
        display: none;
      }

      .private-space-cmt {
        margin-left: 0;
      }

      .mention-textarea-wrap__control {
        font-size: 16px;
      }

      .post-comment-footer {
        max-width: 165px;
        margin-left: 0 !important;

        .osbtn {
          margin-left: 0 !important;
        }
      }
    }

    &.company-share-modal {
      .share-modal-card-holder {
        width: 260px;
      }
    }

    .modal-content {
      padding: 15px;
      padding: 0;
    }

    .modal-header {
      padding: 10px 30px;
      border-bottom: none;

       .card-title {
         i {
          margin-right: 20px;
          position: relative;
          top: 4px;
        }

         b {
          font-weight: $font-weight-bold;
        }
      }
    }

    .modal-body {
      padding: 0 0 40px;
    }

    .share-modal-content-row {
      margin: 0 70px;
      padding: 25px 0;
      text-align: center;

       &.mx-35 {
        margin-left: 30px;
        margin-right: 30px;
      }

       &:first-child {
        border-top: none;
      }

       &.card-row {
        padding: 10px 0;
      }

       &.internal-row {
        margin: 0 10px;
      }
    }

    .share-modal-email-text {
      display: block;
      color: #aaa;
      font-size: 12px;
      letter-spacing: .5px;
    }

    .share-modal-textarea-block {
      margin-top: 10px;
    }

    .share-modal-email-block {
      width: 100%;
      min-height: 33px;
      margin-top: 10px;
      border: none;
      padding: 0;
      position: relative;
      text-align: left;

      input[type="text"] {
        width: 100%;
        border-radius: 4px;
      }
    }

    input:focus {
      outline: none;
    }

    .react-tagsinput-tag {
      width: auto;
      margin: 5px 5px 5px 5px;
      border: 0;
      padding: 0px 26px 0px 8px;
      display: inline-block;
      position: relative;
      color: $level-11;
      font-size: 16px;
      background: $cl-link;
      border-radius: 4px;
      height: 24px;
      line-height: 24px;

      .react-tagsinput-remove {
        position: absolute;
        top: 0px;
        right: 8px;
        cursor: pointer;
        font-family: fill-os-icon;
        font-size: 0;
        &:before {
          content: "\e99d";
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .share-modal-content-row {
      &.share-select-list {
        border-top: none;
        padding: 0px 0 0;

        h6 {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: $font-weight-normal;
        }

        .share-modal-social-row {
          margin-top: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 12px;
        }

        .react-share__ShareButton {
          &:active, &:focus {
            outline: none;
            border: 0;
          }
        }

        .share-modal-social-btns {
          margin-left: 24px;
          border: none;
          cursor: pointer;
          font-size: 32px;
          line-height: normal;
          min-height: 32px;
          min-width: 32px;
          background: none !important;
          border-radius: 0;

          .btn-icon-helper {
            font-size: inherit;
          }

          &:hover {
            outline: none;
          }

          &:first-child {
            margin-left: 0;
          }

          &.osbtn-disabled {
            &:hover {
              color: $level-6;
            }

            &:first-child {
              opacity: 0.3;
            }
          }
        }

        form {
          margin-top: 20px;
        }

        .share-modal-footer-btns-row {
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            &.share-modal-email-btn {
              &.share-invite-modal-btn {
                line-height: 34px;
              }
            }
          }

          .share-invite-modal-btn {
            margin: 0 10px;
          }
        }
      }
    }

    .share-label {
      font-size: 12px;
      font-weight: $font-weight-bold;
    }

    .select-form-group {
      margin-bottom: 16px;
    }

    .share-modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .osbtn {
        min-width: 95px;

        + .osbtn {
          margin-left: 1rem;
        }
      }
    }

    .btn-blue {
      &.width-120 {
        max-width: 120px;
      }

    }

    .btn-link {
      text-decoration: none;
    }


    &.modal-share-entity {
      width: 400px;

      @media screen and (min-width: 1440px) {
        .modal-body {
          .private-space-cmt{
            margin-left: 0;
          }
        }
      }


      .share-modal-card-holder {
        text-align: left;
      }

      .modal-body {
        padding: 0 16px 24px 24px;
      }

      .modal-content {
        border: 0;
      }

      .btn-link {
        font-size: 12px;
      }

      .modal-header {
        padding: 24px 16px 32px 24px;

        .y-center {
          top: calc(50% - 24px);
        }

        .os-header-btn {
          right: 8px;
        }

        @media(max-width: 767px) {
          padding: 24px 60px 0 24px;
          height: 80px;
          align-items: flex-start;

          .modal-title, .card-title {
            @include fontstyle(18px, 600, 1.2);
            text-transform: none;
          }

          .os-header-btn {
            top: 18px;
          }
        }
      }

      .share-modal-content-row {
        border: 0;
        margin: 0 0 -70px 0;
        padding-bottom: 0;
        padding-top: 0;
        &.share-select-list {
          margin-bottom: 0;
        }
      }

      .share-label {
        font-size: 16px;
        line-height: 1;
      }

      .os__value-container {
        padding: 4px 16px;
        z-index: 1;
        cursor: pointer;
      }

      textarea {
        &.form-control {
          font-size: 16px;
          min-height: 80px;
          resize: none;
        }
      }

      .is-focused > .Select-control {
        border: 1px solid  $border-active !important;
      }

      .single-select,
      .multi-select {
        .ifill-os-chevron-down,
        .ifill-os-chevron-up {
          position: absolute;
          right: 10px;
          top: 14px;
          z-index: 0;
        }
      }

      .single-select,
      .multi-select {
        .os__value-container {
          max-width: calc(100% - 70px);
        }
      }
      .react-select-ortho-2{
        max-width: 100%;

        .Select-menu-outer {
          max-height: 200px;
        }

        .os__indicators {
          position: absolute;
          right: 40px;
          top: 4px;
          height: 32px;

          &:after {
            content: "\e903";
            font-family: 'fill-os-icon';
            position: absolute;
            right: -32px;
            cursor: pointer;
          }

          svg {
            margin-top: 4px;
          }
        }

        .os__control--menu-is-open {
          .os__indicators {
            &:after {
              content: "\e906";
              font-family: 'fill-os-icon';
            }
          }
        }

        .os__clear-indicator {
          &:after {
            position: absolute;
            width: 1px;
            height: 12px;
            content: '';
            right: -2px;
            background-color: $level-9;
            top: 12px;
          }
        }

        .os__control {
          max-height: 170px;
          overflow-x: hidden;
          overflow-y: auto;
          cursor: pointer;
        }

        .Select-menu,
        .os__menu-list {
          max-height: 140px;
          font-size: 14px;
          overflow-y: auto;
          list-style: none;
        }

        .os__menu {
          z-index: 2;
        }

        .os__menu-list {
          z-index: 2;

          .os__option {
            cursor: pointer;
            padding: 0;
          }

          .result-text-data {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .avatar-combo {
          position: relative;

          .avatar {
            vertical-align: middle;
            top: -4px;
            position: relative;

            &:only-child {
              top: 0;
            }
          }

          .avatar + .avatar {
            position: absolute;
            top: 4px;
            left: 10px;
          }
        }

        .avatar {
          @include avatar(24px);
          margin-right: 14px;
        }

        .Select-control{
          min-height: 40px;
          border: 1px solid transparent;
        }

        .Select-value{
          position: relative;
          padding-right: 20px;
        }

        .Select-input {
          height: 40px;
          > input {
            padding-top: 0px;
            padding-bottom: 0px;
            height: 40px;
          }
        }
        .Select-value-icon{
          right: 6px;
          position: absolute;
          z-index: 9999;
          background: transparent !important;
          border-radius: 0;
          width: 18px;
          height: 18px;
          line-height: 15px;
          text-transform: uppercase;
          font-size: 20px;
          top: 6px;
          text-align: center;
          padding: 0;
          font-family: fill-os-icon;
          font-size: 0;
          font-weight: bold;
          line-height: 18px;
          text-align: center;
          box-shadow: none;
          border: 0;
          &:before {
            content: "\e99d";
            font-size: 8px;
          }
        }
        .Select-clear-zone{
          width: 0;
        }
        .Select-arrow-zone {
          display: none;
          width: 0;
          padding-right: 0;
        }

        .Select-placeholder {
          color: $level-9;
          line-height: 40px;
        }

        .Select-value{
          position: relative;
          padding: 0 34px 0 5px;
          background: $dropdown-list-hover;
          margin-top: 3px;
          color: #fff;
          line-height: 25px;
        }

        .Select-multi-value-wrapper {
          overflow-y: auto;
          display: block;
          min-height: 40px;
          max-width: 472px !important;
          overflow-x: auto;
        }
      }

      &.share-discussion {
        .react-select-ortho-2 {
          .avatar {
            border-radius: 4px;
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    &.modal-share {
      width: calc(100% - 30px);
      margin: 0 auto;

      .modal-header {
        padding: 15px 15px 10px;

        .card-title {
          font-size: 18px;
          line-height: normal;

          i {
            width: 14px;
            height: 16px;
            margin-right: 10px;
            top: 2px;
            background-size: 14px auto;
            &.ifill-os-share {
              font-size: 14px;
              top:0;
            }
          }
        }
      }

      .share-modal-content-row {
        margin: 0;

        &.internal-row {
          padding: 10px 0;
        }

        &.email-row {
          padding: 10px;
        }

        &.share-select-list {
          .share-modal-social-row {
            width: 100%;
            display: flex;
            justify-content: center;

            .share-modal-social-btns {
              font-size: 24px;
              min-height: 24px;
              min-width: 24px;
            }

            .icon-os-qlark{
              width: 24px;
              height: 24px;
              background-size: 24px auto;
              margin-left: 0;
            }
          }

          .share-modal-footer-btns-row {
            div {
              &.share-modal-email-btn {
                &.share-invite-modal-btn {
                  line-height: 26px;
                }
              }
            }
          }
        }
      }

      .share-internal-btn {
        padding: 5px 12px;
        font-size: 11px;
      }

      .share-modal-email-btn {
        height: 26px;
      }

      &.modal-get-in-touch {
        .modal-header {
          padding: 20px 50px 20px 20px;
        }
        .share-modal-content-row {
          &.email-row {
            padding: 0px;
          }
        }
      }

      &.modal-share-entity {
        width: 345px;

        .react-select-ortho-2 {
          .Select-multi-value-wrapper {
            max-width: 297px !important;
          }
        }

        .share-modal-card-holder {
          margin: 0px auto;
        }
      }
    }
  }

  &.modal-create-notes {
    border-radius: 10px;
    width: 600px;
    margin-top: 0;
    position: relative;
    top: 10%;
    height: 80%;

    .note-heading{
      position: relative;
      padding: 0 20px;
      top: 24px;
      z-index: 2;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #000000;
      padding-bottom: 15px;
      min-height: 30px;
      .note-card{
        .card-type{
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          color: $level-9;
          .icon-comparison {
            width: 18px;
            height: 17px;
          }

          span{
            width: 120px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 8px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
    i.ifill-os-close {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 10px;
      top: 0px;
      cursor: pointer;
    }

    .dropzone-drag-uploader-container {
      top: 5px;
      right: 0;
      bottom: 5px;
      left: 0;

      &.dropzone-drag-active {
        background-color: white;
        opacity: 0.8;
        border: dashed;
        border-color: black;
      }
    }
    .modal-content {
      padding: 15px;
      padding: 0;
      border-radius: 8px;
      max-height: 100%;
      min-height: 250px;
      justify-content: space-between;
      flex-direction: column;

      .note-form {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 0px;
        -webkit-overflow-scrolling: touch;
        border-radius: 8px 8px 0 0;
      }
    }

    .modal-scroll-wrap {
      /*max-height: calc(100% - 80px);
      overflow-y: auto;*/
      padding-bottom: 0px;
      border-radius: 0 0 10px 10px;
      margin-bottom: 10px;
      margin-top: 24px;
      max-height:calc(100% - 80px);
      &.with-large-text {
        max-height: calc(100% - 135px);
      }
    }

    [contenteditable=true]:empty:before {
      content: attr(placeholder);
      display: block; /* For Firefox */
      opacity: 0.5;
    }

    &.with-attachment {
      .modal-content {
        .modal-scroll-wrap {
          border-radius: 0;
          margin-bottom: 0px;
          margin-top: 24px;
        }

        .conversation-attachment-file-holder {
          max-width: 32.5%;
          margin-right: 5px;
          margin-bottom: 5px;
          position: relative;
          z-index: 1;

          &:nth-child(3n) {
            margin-right: 0px;
          }

          .ifill-os-close {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
          }

          .uploaded-file {
            width: 100%;
            margin: 0;
            padding: 0;
            position: relative;
            text-align: center;
            background: transparent;

            img {
              max-width: 100%;
              max-height: 125px;
            }
            cursor: pointer;
          }

          .attachment-close-link {
            position: absolute;
            right: 0px;
            width: 23px;
            height: 23px;
            background: rgba(0, 0, 0, 0.4);
            text-align: center;
            font-size: 18px;
            top: 0px;
            line-height: 19px;
            cursor: pointer;
            z-index: 1;
          }

          .loader-active {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            min-height: auto;

            .loader-percentage {
              z-index: 1;
              font-weight: bold;
            }

            &::before {
              content: '';
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.6);
              position: absolute;
            }
            .loader-inner {
              margin-top: 0px;
            }
          }
        }
      }
    }
    .notes-attachment-preview{
      max-width: 100%;
    }

    .create-tag{
      cursor: pointer;
      color: $level-11;
      background-color: $block;
      border-radius: 10px;
      padding: 3px 10px;
      width: auto;
      font-size: 12px;
      margin-left: 10px;
      &.hide{
        display: none;
      }
    }

    .modal-header {
      width: 100%;
      padding: 0px 20px 0px 20px;

      .modal-title {
        width: 100%;
        z-index: 1;
        position: relative;
      }

      .form-group {
        margin-bottom: 0;
      }

      .form-error {
        padding-right: 0.75rem;
      }
      .form-control{
        width: 100%;
        height: 40px;
        border: none;
        font-size: 18px;
        background: transparent;
      }
    }

    .form-error {
      font-size: 12px;
    }

    .modal-body {
      padding: 0 20px 20px;
      .form-group {
        padding-right: 0.75rem;
        margin-bottom: 0;
      }

      .form-control{
        width: 100%;
        min-height: 80px;
        border: none;
        color: $level-11;
        font-size: 15px;
        background: transparent;

        &:focus {
          outline: none;
        }
      }
    }

    .notes-content-div {
      position: relative;
      min-height: 100px;

      .contentEditable,
      .textarea-field {
        font-size: 14px;
        margin: 0;
        padding: 0;
        letter-spacing:  0.5px;
      }

      .contentEditable {
        pointer-events: none;
        user-select: none;
        opacity: 0;
        visibility: hidden;
        line-height: 1.5;
        white-space: pre-line;
      }

      .textarea-field {
        position: absolute;
        resize: none;
        width: calc(100% - 40px);
        height: 100%;
        color: $level-11;
        top: 0;
        left: 20px;
        padding: 0;
        max-height: 100%;
        overflow: hidden;
        line-height: 1.5;

        &:focus {
          border: 0;
          outline: none;
          box-shadow: none;
        }
      }
    }

    .modal-footer {
      display: block;
      white-space: nowrap;
      border-radius: 0px 0px 10px 10px;
      margin: 0;

      .modal-footer-content {
        position: relative;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        bottom: 0px;
        padding-top: 0px;
        margin: 0;

        .notes-modal-attachment-block {
          margin-left: 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .notes-modal-attachment-link {
            margin-top: 5px;
          }
          .notes-delete-icon {
            position: absolute;
            margin-left: 48px;
          }

          .disable-close {
            cursor: not-allowed;
          }
        }
        .btn-link {
          text-decoration: none;
          padding:0 0;
          min-width: auto;
          text-transform: uppercase;
          font-weight: bold;
        }

        &.cursor-pointer {
          cursor: pointer;
        }
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) {
      .modal-content {
        .note-form {
          -webkit-overflow-scrolling: touch;
        }
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      top: 0;
      margin: 0;
      border-radius: 0;

      .modal-content {
        border-radius: 0px;
        .note-form {
          -webkit-overflow-scrolling: touch;
        }
      }

    }
  }

}
.modal-xl {
  &.modal-share {
    &.modal-invite-user {
      .modal-header {
        border-bottom: solid 1px rgba(255, 255, 255, .2);

        .card-title {
          font-size: 18px;
          font-weight: $font-weight-light;
          letter-spacing: 0;
          text-transform: none;

          i {
            top: 1px;
          }
        }
      }

      .invite-modal-content-block {
        padding: 5px 25px 25px;

        .modal-header {
          padding: 0 0 5px;
        }

        .invite-user-content-row {
          margin-top: 20px;
          display: flex;
          font-size: 14px;

          &:first-child {
            margin-top: 0;
          }

          label {
            width: 38%;
            margin: 0;
            font-weight: $font-weight-normal;
            line-height: 16px;
          }

          .invite-user-content-item {
            width: 100%;
            padding-left: 10px;
            line-height: 16px;
          }
        }
      }

      .share-modal-content-row {
        padding:30px 0 0;

        .share-modal-email-block {
          margin-top: 0;
          + .form-error {
            position: absolute;
          }
        }

        .share-modal-textarea-block {
          margin-top: 30px;
        }

        .share-modal-email-btn {
          width: 120px;
          margin: 0;
          bottom: 30px;
          font-weight: $font-weight-bold;
          border-radius: 4px;

          .fa {
            margin-left: -12px;
            margin-right: 8px;
          }
        }
      }

      .share-modal-height {
        min-height: 213px;

        &.share-md-relative {
          .share-modal-email-btn {
            bottom: 0px;
            position: relative;
            left: 0;
            margin-top: 25px;
            margin-left: 0;
          }
        }

        @media screen and (max-width: 767px ) {
          min-height: 178px;
        }
      }

      .modal-body {
        padding-bottom: 30px;
      }
    }
    &.modal-share-invite {
      .modal-header {
        .card-title {
          i {
            top: 4px;
            margin-right: 10px;
          }
        }
      }

      .os-header-btn {
        right: 13px;
        top: 6px;
      }
    }
  }

  @media only screen and (min-width : 320px) and (max-device-width : 767px) {
    &.modal-share {
      &.modal-invite-user {
        .invite-modal-content-block {
          padding: 10px 15px;

          .modal-header {
            padding: 0 0 8px;

            .card-title {
              font-size: 14px;

              i {
                margin-right: 5px;
                top: 6px;
                &.ifill-os-invite {
                  top: 1px;
                }
              }
            }
          }

          .invite-user-content-row {
            margin-top: 15px;
            border-bottom: solid 1px rgba(255, 255, 255, 0.2);
            padding-bottom: 15px;
            flex-wrap: wrap;
            font-size: 12px;

            &.btn-row {
              margin-top: 15px;
              border-bottom: none;

              .btn {
                height: 28px;
              }
            }

            label {
              width: 100%;
              font-weight: $font-weight-bold;
            }

            .invite-user-content-item {
              width: 100%;
              margin-top: 2px;
              padding-left: 0;
            }
          }
        }
      }
      &.modal-share-invite {
        .modal-header {
          .card-title {
            i {
              top: 3px;
              margin-right: 10px;
            }
          }
        }

        .os-header-btn {
          min-width: 32px;
          min-height: 32px;
          line-height: normal;
          right: 6px;
          top: 4px;

          i {
            font-size: 14px;
          }
        }
      }
    }
  }
}


.modal-invite-user {
  .react-tagsinput {
    border: solid 1px transparent;
    border-radius: 4px;

    .text-field {
      border: 0;
      border-radius: 0;
    }
  }

  .react-tagsinput--focused {
    border-color: $os-link;
  }

  .tag-error {
    border-color: $lt-red;
  }
}

.mobile-device {
  .modal-xl.modal-share.modal-share-entity {
    .react-select-ortho-2 .os__control {
      max-height: 120px;
    }
  }
}

.share-option-dropdown {
  @include fontstyle(16px, normal, 1.33);
  border-radius: 12px;
  min-height: 40px;
  color: $level-10;
  cursor: pointer;
  margin-bottom: 16px;
  border: 1px solid $input-border;

  .arrow-down {
    float: right;
    margin-top: 4px;
  }

  .page-heading-title {
    display: flex;
    align-items: center;

    i {
      margin-right: 0.5rem;
      color: $level-9;
    }

    .istoggleicon {
      color: $level-9;

    }

    .sort-rel-icon {
      position: absolute;
      left: 16px;
    }

    span {
      width: 100%;
      padding: 0 0 0 16px;
      line-height: 40px;
      color: $level-11;
    }

    .sort-rel-icon:not(.undefined){
      + span {
        padding-left: 40px;
      }
    }
  }

  .header-dropdown-list {
    width: 100%;
    top: calc(100% + 2px);
    margin: 0;
    overflow: hidden;

    > div {
      height: 100%;
    }
  }

  .header-dropdown-list-item {
    min-height: 40px;
    padding: 0;
    display: flex;
    cursor: pointer;

    &::before {
      display: none;
    }

    i {
      font-size: 16px;
    }

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 16px;
    }

    &.active {
      background: $dropdown-list-hover;
      color: $level-11;
    }
  }
}

.no-touch-support {
  .share-option-dropdown {
    .header-dropdown-list-item {
      &:hover {
        background: $dropdown-list-hover;
        color: $level-11;
        i {
          color: $level-10;
        }
      }

      span {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}


.polaris-feed {
  .share-option-dropdown {
    display: none;
  }
}

.modal-share-entity {
  .comment-attachment-block {
    [class^="col-"] {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .write-post-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .add-post-area  {
    padding: 0 !important;
    background: transparent;
    margin: 0;
    box-shadow: none;
  }

  .post-textarea-wrap {
    > div:not(.post-textarea) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .share-label {
      color: $level-9;
      margin-bottom: 0;
      line-height: 24px !important;
      margin-bottom: 4px;
    }
  }

  .post-can-view-dropdown {
    .header-dropdown-list {
      right: 0;
      left: auto;
    }

    .page-heading-title {
      height: auto;
      color: $level-9;
    }

    .istoggleicon {
      color: $level-9;
    }
  }

  .post-footer-section {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    .osbtn:first-child {
      margin-right: 16px !important;
    }
  }

  .media-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-bottom: 16px;

    > div {
      display: inline-block;
      margin-left: 24px !important;
    }

    .btn-group-right {
      flex-basis: 100%;
      width: 100%;
    }
  }

  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    min-height: 80px !important;
    height: 80px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .post-textarea {
    padding: 0 !important;
  }

  .post-footer-section {
    padding-right: 0;
  }
}

.mobile-device {
  .modal-share-entity {
    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 80px !important;
      height: 80px !important;
      padding: 12px !important;
      font-size: 14px !important;
      letter-spacing: -0.5px !important;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        line-height: 1.2;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        line-height: 1.2;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        line-height: 1.2;
      }
      &:-moz-placeholder { /* Firefox 18- */
        line-height: 1.2;
      }
    }

    .os-card-v2 {
      max-width: 360px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .pulse-add-new {
    .post-footer-section {
      .select-box-wrap .os-header-btn {
        top: 0;
      }
    }
  }
}
