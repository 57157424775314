
.tab-link-tooltip {
  @include x-center;
  top: 50%;
  padding-top: 10px;
  visibility: hiedden;
  opacity: 0;
  transition: all 300ms ease;
  pointer-events: none;
  width: 264px;
  border-radius: 8px;
  box-shadow: $block-xs;

  .tooltip-container {
    background: $block;
    padding:  16px 24px 16px 48px;
    border-radius: 8px;
    box-shadow: 0 1.3px 4px 0 rgba(0, 0, 0, 0.25);
    color: $level-11;
    font-size: 12px;
    text-transform: none;
    position: relative;



    .header-list-icon {
      font-size: 16px;
      color: $level-8;
      left: 20px;
      top: 20px;
      position: absolute;

      &.ifill-os-info {
        color: $lt-red;
        top: 16px
      }
    }
  }

  .tooltip-arrow-top {
    @include x-center;
    top: -6px;
    font-size: 24px;
    color: $block !important;
  }
}

.activity-data-col {
  flex: 1;
  margin-bottom: 24px;
}

%border-activity {
  border-top: solid 1px #646464;
  padding-top: 14px;
}

.activity-listing {
  +.activity-listing .activity-listing-data {
    margin-top: 9px;
  }
}

.activity-listing {
  p:empty {
    min-height: 16px;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    line-height: 1.25;
  }

  strong {
    font-size: 16px;
    font-style: normal;
    line-height: 1.5;
  }

  p,
  .parse-comment-text {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
  }

  .parse-comment-text {
    a {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.activity-listing {
  @media only screen and (min-width: 1920px) {
    p:empty {
      min-height: 20px !important;
    }

    h1 {
      font-size: 24px !important;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
    }

    h2 {
      font-size: 20px !important;
      font-style: normal;
      line-height: 1.25;
    }

    p,
    .parse-comment-text {
      font-size: 20px !important;
      font-weight: normal;
      font-style: normal;
      line-height: 1.5;
    }

    .parse-comment-text {
      a {
        font-size: 16px !important;
        line-height: 1.5;
      }
    }
  }
}

.top-zero {
  .backtotop {
    display: none;
  }
}

.backtotop {
  border-radius: 30px;
  background-color: $block;
  padding: 0 12px !important;
  min-height: 36px !important;
  line-height: 36px !important;
  text-transform: none;
  font-weight: normal;
  font-size: 12px !important;
  transition: all 300ms !important;
  position: fixed;
  bottom: 42px;
  left: 50%;
  z-index: 11;
  overflow: hidden;

  .btn-icon-helper {
    left: 0 !important;
    top: 1px !important;
    font-size: 12px !important;
    position: relative;
    transform: none !important
  }

  .back-text {
    text-transform: none;
    position: absolute;
    color: $os-link;
    opacity: 0;
    width: 0;
    padding: 0;
    transition: all 100ms cubic-bezier(.62, .1, .5, 1);
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    padding: 0 16px !important;

    .btn-icon-helper {
      color: $os-link;
    }

    .back-text {
      position: relative;
      opacity: 1;
      padding-left: 6px;
      transition-duration: 400ms;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    left: 50%;
    top: 6px;
    bottom: auto;
    transform: translateX(-50%);
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    transform: translateX(-50%);
    left: 50%;
    top: 72px;
    bottom: auto;
    padding: 4px 8px !important;
    font-size: 12px;
    min-height: 20px !important;
    line-height: 20px !important;
    margin-left: 0;

    &:hover,
    &:focus,
    &:active,
    &.active {
      padding: 4px 8px !important;

      .back-text {
        padding-left: 4px;
        display: none;
      }
    }
  }

}

.scroll-up {
  .backtotop {
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      top: 84px;
    }
  }
}

.users {
  .backtotop {
    @media screen and (max-width: 767px) {
      top: 120px;
    }
  }
}

.activity-listing {
  .backtotop {
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      &:first-child {
        position: sticky;
        left: 50%;
        transform: translateX(-50%);
        top: 6px;
        z-index: 4;
      }
    }

    @media screen and (max-width: 767px) {
      position: sticky;
      left: 50%;
      transform: translateX(-50%);
      top: 84px;
      z-index: 4;
    }
  }
}

.user-timeline-section {
  .activity-listing {
    .backtotop {
      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        top: 50px;
      }

      @media screen and (max-width: 767px) {
        top: 115px;
      }
    }
  }
}

.activity-listing-item {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  background-color: $block;
  border-radius: 12px;
  padding: 0;
  margin-bottom: 24px;
  box-shadow: $block-shadow;

  &.processed {
    background-color: $level-3;

    .group-by-space-name {
      background-color: $level-4;
    }

    .space-v2-card {
      background-color: $level-3;
    }
  }

  .activity-listing-item-content {
    padding: 32px 40px 32px 88px;
    width: 100%;

    .user-info-row+.media-body {
      padding-right: 40px;
    }

    @media screen and (min-width: 1441px) {
      padding-right: 48px;

      .user-info-row+.media-body {
        padding-right: 64px;
      }

    }

    @media screen and (max-width: 992px) {
      .user-info-row+.media-body {
        padding-right: 0;
      }
    }
  }



  .comment {
    display: block;
  }

  .card-btn-more {
    padding: 0 12px;
  }


  &:empty {
    display: none !important;
  }

  .user-info-row {
    margin-bottom: 0;

    .user-name-block {
      .time-with-seenlist {
        // display: flex;
        margin-top: -1px;

				// &>span {
				// 	margin-right: 4px;

				// 	.ifill-os-locked, .ifill-os-views {
				// 		color: #5d7893;
				// 	}
				// }
      }
    }
  }

  &.active-post {
    background-color: #c1d7f7;
  }

  pre {
    position: relative;
  }

  .user-info-row {
    .user-name-block {
      .user-link,
      .degree-text {
        font-size: 16px;
        line-height: 20px;

        @media screen and (min-width: 1920px) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  &.post-detail-item {
    .emoji-btns-group {
      margin-bottom: 0;
    }
  }

  &.trending-case-block {
    padding: 32px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .activity-listing-item {
    .activity-listing-item-content {
      padding: 32px 48px 32px 32px;
    }

    &.pinned-post {
      .pinned-post-block {
        margin-left: 0;
      }
    }
  }
}

.activity-heading-block {
  @include fontstyle(16px, normal, 24px);

  @media(min-width: 1024) {
    font-size: 12px;
  }

  color: #fff;
  display: flex;
  flex-wrap: wrap;

  .time-stamp {
    padding-left: 5px;
  }

  .time-day {
    position: relative;
  }

  .white-link {
    color: $level-10;
    position: relative;
  }
}

.activity-listing-data {
  flex: 1;
  max-width: 100%;
}

.user-info-row {
  flex-wrap: nowrap;
  margin-bottom: 0;

  .avatar-listing {
    margin-right: 16px;
    white-space: nowrap;
    line-height: 1;

    .icon-span {
      right: -2px;
      bottom: -2px;
    }

    .avatar-40 {
      .icon-span {
        line-height: 16px;
      }
    }

    @media screen and (min-width: 1440px) {
      &.multiple {
        +.user-name-block {
          padding-left: 16px;
        }

        .act-avatar-item {
          +.act-avatar-item {
            margin-left: -24px;
          }
        }
      }
    }
  }

  .act-avatar-item {
    position: relative;
    z-index: 1;
    display: inline-flex;

    +.act-avatar-item {
      margin-left: -10px;
    }

    .avatar {
      vertical-align: middle;
    }
  }

  .user-name-block {
    font-weight: $font-weight-bold;
  }

  a.user-link,
  .user-link {
    color: $level-11;
    font-weight: $link-weight;

    &:hover {
      color: $os-link-hover;
    }
  }

  .media-comment-time {
    color: $level-9;

    @include fontstyle(12px, $font-weight-normal, 16px);

    @media screen and (min-width: 1920px) {
      font-size: 16px;
      line-height: 24px;
    }

  }
}

.user-related-data {
  font-size: 18px;

  .text-info {
    opacity: .9;
  }
}

.activity-last-comment-info,
.activity-comment-listing-item {
  padding-top: 15px;
}

/* .no-touch-support {
  .activity-listing-item {
    .user-name-block {
      @include text-truncate();
      max-width: calc(100% - 50px);
    }
  }
} */

pre.nf-text-info {
  font-size: 16px;

  .link-text {
    color: $os-link;
  }
}

pre.activity-listing-content {
  font-size: 16px;
  line-height: 1.5;
  color: #d4d4d9;
  font-weight: normal;
  margin-bottom: 0;

  .link-text {
    color: $os-link;
  }
}

pre {
  .a-link {
    font-size: 16px;

    @media screen and (min-width: 1920px) {
      font-size: 20px;
    }
  }
}

.your-reply-area {
  padding-top: 0;
  padding-bottom: .5rem;
  color: #fff;
}

.seen-all-post {
  width: 100%;
  border-radius: 12px;
  background-color: $level-4;
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  color: $level-11;
  padding: 16px 0;
  font-weight: normal;
  line-height: 16px;

  i {
    color: $level-8;
    margin-right: 8px;
  }
}

.os-card-listing,
.card-row-pulse {
  >[class^="col-"],
  .min-card-col {
    min-width: 262px;

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      min-width: 360px;
    }
  }
}

.os-card-listing {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.activity-listing-item {
  .comment-reply-list {
    .reply-ul {
      padding-left: 0;

      .comment {
        padding-left: 0;
      }
    }
  }
}

.ipad {
  .comments-list {
    .comment {
      padding: 0;
    }
  }

  &.landscape {
    .activity-listing-item {
      .comment-reply-list {
        .reply-ul {
          .comment {
            padding-right: 0;
          }
        }
      }
    }
  }
}


@media (min-width: 1030px) {
  .no-touch-support {
    .seen-all-post {
      position: relative;
    }
  }
}

.mobile-device {
  &.portrait {
    .activity-listing-item {
      margin-bottom: 16px;

      .activity-listing-item-content {
        padding: 16px;
      }

      ~.activity-listing-item {
        margin-top: 16px;
      }

      .heading-title {
        margin-bottom: 0;
        margin-right: 10px;
      }

      .activity-listing-data {
        border: 0;
        padding-top: 0;
      }

      .your-reply-area {
        padding-bottom: 0;
      }

      .time-day {
        font-size: 12px;
        line-height: 16px;
      }

      .user-info-row {
        .act-avatar-item {
          .avatar-48 {
            @include avatar(32px);

            .icon-span {
              @include icon-span(16px);
            }
          }
        }

        .user-name-block {
          .user-link,
          .degree-text {
            font-size: 16px;
          }
        }

        .avatar-listing {
          margin-right: 8px;
        }
      }

      pre {
        font-size: 16px;
        line-height: 24px;

        .a-link {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &.with-day-heading:first-child {
        .activity-listing-data {
          padding-top: 0;
          max-width: 100%;
        }
      }
    }

    .activity-heading-block {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.landscape {
    .activity-listing-item {
      +.activity-listing-item {
        .activity-listing-data {
          margin-top: 8px;
        }
      }

      ~.with-day-heading {
        .activity-listing-data {
          margin-top: 21px;
        }
      }
    }
  }
}

.mb-26 {
  margin-bottom: 26px;
}

.ipad {
  &.landscape {
    .activity-data-col {
      margin-bottom: 50px;
    }
  }
}

.no-touch-support {
  @media only screen and (min-width: 1440px) {
    .activity-listing-item {
      .avatar-listing {
        position: absolute;
        left: 32px;
      }

      .carespace-avatar {
        left: -56px !important;
      }

      .discussion-thread-section {
        .avatar-listing {
          position: relative;
          left: 0;
        }
      }

      .avatar.avatar-40 {
        @include avatar(40px);

        .icon-span {
          @include icon-span(16px)
        }
      }

      .user-info-row {
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
}

.activity-footer-block {
  @include flex_space_between;

  position: relative;

  &.comment-section {
    margin-bottom: 8px;
    margin-top: 4px;

    @media screen and (max-width: 767px) {
      margin-top: 16px;

      .user-emoji-list {
        display: inline-flex;
        align-items: center;
        max-width: calc(100% - 120px);
      }

      .user-emoji-holder~span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .emoji-bar {
    @media screen and (min-width: 1200px) {
      margin-left: -12px;

      .emoji-btns-group {
        .blank-emoji-holder+.user-emoji-list {
          margin-left: 12px;
        }
      }
    }
  }

  .osbtn-icon {
    &:not(.no-text),
    &.px-8 {
      padding-left: 40px !important;

      .ifill-os-like,
      .ifill-os-comment {
        left: 16px !important;
      }
    }

    .btn-icon-helper {
      font-size: .9rem;
      transition: color 500ms ease;
      will-change: color;
    }

    &.os-share-button {
      margin-right: 0 !important;
      padding-right: 16px;
    }

    &.osbtn-reply {
      position: absolute;
      right: -16px;
      bottom: 0;

      @media screen and (max-width: 767px) {
        margin-left: auto;
        bottom: 3px;
        margin-right: 0 !important;
        padding-right: 0;
        right: -10px;

        .btn-icon-helper {
          left: 8px;

          &.ifill-os-reply-left {
            left: 16px;
          }
        }
      }

    }
  }

  .reply-buttons {
    .osbtn-icon {
      min-height: 48px !important;
    }
  }

  .left-col,
  .right-col {
    display: inline-flex;
  }

  .right-col {
    position: absolute;
    right: 0;
    bottom: 0;

    .osbtn-icon {
      +.osbtn-icon {
        margin-left: 0 !important;
      }

      &.px-8 {
        &:first-child {
          padding-right: 16px;
        }
      }
    }
  }

  .osbtn-disabled {
    &:hover {
      background-color: transparent !important;
    }
  }

  .user-emoji-holder {
    width: 20px;
    height: 40px;
    margin-right: 8px;

    span {
      padding: 0 !important;
    }

    >div {
      margin: auto;
      line-height: 1;
    }
  }

  .emoji-btns-group {
    .emoji-btn-icons {
      margin-right: 0 !important;
    }

    .emoji-counter {
      color: $level-9;
      font-weight: $font-weight-semibold;
      line-height: 1;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.touch-support {
  .activity-footer-block {
    .osbtn-icon {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.mobile-device {
  body:not(.cases) {
    .activity-footer-block {
      .osbtn-icon {
        &:not(.no-text),
        &.px-8 {
          &.os-share-button,
          &.osbtn-reply {
            padding-left: 32px !important;
          }

          .ifill-os-like,
          .ifill-os-comment,
          {
          left: 8px !important;
        }
      }

      &.osbtn-reply {
        margin-right: 8px;
      }
    }
  }
}
}

.activity-listing-item {
  .discussion-thread-section {
    .comment {
      padding-left: 0;
      padding-right: 0;

      &:only-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .media-body {
      padding-left: 0;
    }
  }
}

.no-touch-support,
.touch-support {
  &:not(.mobile-device) {
    div:not(.card-content)> {
      .activity-footer-block {
        .osbtn {
          &.osbtn-icon {
            &.px-8,
            &.text-required {
              padding-left: 40px;
              padding-right: 16px;

              .btn-icon-helper {
                left: 16px;

                +span {
                  padding-left: 0;
                }
              }
            }

            .ifill-os-like {
              font-size: 14px;
            }
          }

          &:not(.osbtn-icon) {
            padding-right: 16px;
            padding-left: 16px;
          }
        }

        .right-col {
          .osbtn {
            &.no-text {
              margin-right: 4px;
            }
          }
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .right-col {
            .osbtn {
              &:last-child {
                margin-right: 0;
              }

              &.no-text {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.ipad {
  &.portrait {
    .activity-footer-block {
      .left-col {
        .osbtn {
          padding-right: 10px !important;
          margin-right: 16px;
        }
      }
    }
  }
}

.mobile-device {
  body:not(.cases) {
    .activity-footer-block {
      .osbtn {
        &:last-child {
          span {
            padding-right: 5px;
          }
        }

        &.osbtn-icon {
          padding-right: 16px;
          padding-left: 16px;
          vertical-align: middle;
        }
      }

      .left-col {
        .osbtn {
          padding-right: 10px !important;
        }
      }
    }
  }

  .reply-ul {
    .activity-footer-block {
      .right-col {
        display: flex;
      }

      &.comment-section {
        .right-col {
          min-width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}

.comment-section {
  .reply-btn-comment {
    flex-basis: 100px;
    max-width: 100px;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 4px;
  }
}

.dot-with-select {
  display: inline-flex;
  align-items: center;

  .header-list-icon {
    zoom: 1.7;
    color: #7E91A5;
    margin-right: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .cm-process-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 20px;
    padding-top: 7px;
    color: $level-8;
    text-align: center;
    margin-right: 8px;

    .cm-process-icon {
      &.ifill-os-checkmark-round {
        color: $lt-green;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .tab-link-tooltip{
      .tooltip-container{
        padding: 16px 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        .header-list-icon{
          position: unset;
          margin-right: 0;
        }
      }
    }
  }
}