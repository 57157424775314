.ws-enter-box {
  background: $level-1;
  box-shadow: $block-shadow;
  border-radius: 12px;
  color: $level-11;
  text-align: center;
  min-height: 272px;
  padding: 40px 20px;
  transition: all 500ms ease;

  .ws-small-title {
    font-size: 0.75rem;
    line-height: 1.33;
    font-weight: $font-weight-semibold;
    color: $level-9;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .ws-title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    padding: 0 0 24px;
    @media(min-width: 768px) {
      @include text-truncate;
    }
  }

  .oval {
    background: $lt-notification-bg;
    color: $lt-notification-text;
    line-height: 20px;
    position: absolute;
    right: auto;
    left: calc(100% - 18px);
    top: 2px;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    border-radius: 6px;
    padding: 0 4px;
    height: unset;
    width: unset;
    min-width: 20px;
  }
}

.ws_logo_notificaiton {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
  margin-bottom: 24px;

  .ws-logo {
    height: 100%;
    border-radius: 80px;
    overflow: hidden;
    display: flex;
    align-items: center;

    &.with-bg {
      background: $level-6;
      color: $level-1;
    }

    .workspace-icon-logo {
      margin: auto;
      font-size: 20px;
    }
  }

  img {
   width: 100%;
   height: 100%;
   font-size: 10px;
   object-fit: cover;
  }

  .instructor-info-text {
    display: none;
  }

  .ins-avtar-col {
    width: 100%;
  }

  .avatar {
    background: $level-6 !important;
    color: $level-1;
    border: 0 !important;
  }

  .avatar-80 i {
    font-size: 20px;
  }
}

.ws-info {
  text-align: center;
  color: $level-9;
  font-size: 20px;
  line-height: 1.4;
  margin: 64px auto 8px;
  width: 465px;

  h1 {
    color: $level-11;
    font-size: 24px;
    line-height: 1.17;
    padding: 0;
    margin: 0 0 1rem;
    font-weight: $font-weight-black;
  }
}

.ws-section-heading {
  color: $level-9;
  font-size: 16px;
  line-height: 1.25;
  font-weight: $font-weight-semibold;
  margin: 28px 0 20px;
}

.without-ws-heading {
  margin-top: 28px;
}

.no-touch-support {
  .ws-enter-box {
    &:hover {
      box-shadow: $block-shadow-m;
      transform: translateY(-8px);
    }
  }
}

@media(max-width: 767px) {
  .ws-hero-box {
    margin-bottom: 12px !important;
  }

  .ws-enter-box {
    max-width: 89.46%;
    margin: auto;
    padding: 12px;
    display: flex;
    min-height: 96px;
    align-items: center;
    position: relative;

    .ws-title {
      font-size: 12px;
      font-weight: normal;
      padding-bottom: 0;
    }

    .oval {
      position: static;
      line-height: 12px;
      min-width: 18px;
      text-align: center;
      font-size: 8px;
      margin-left: 4px;
      border-radius: 5px;
    }
  }

  .ws-enter-link {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
  }

  .ws_logo_notificaiton {
    width: 128px;
    height: 72px;
    border-radius: 8px;
    margin: 0;

    .ws-logo {
      border-radius: 8px;
    }

    .avatar {
      width: 100%;
      border-radius: 8px;
      height: 72px;
    }
  }

  .ws-box-detail {
    padding-left: 16px;
    text-align: left;
  }

  .ws-info {
    width: 100%;
    max-width: 240px;
    font-size: 16px;
    margin: 32px auto 16px;
    font-size: 12px;
    line-height: 1.33;

    h1 {
      font-size: 24px;
      font-weight: $font-weight-bolder;
    }
  }
}

.without-ws-heading {
  padding-top: 40px;

  @media screen and (max-width: 767px) {
    padding-top: 16px;
  }
}
