.react-datepicker {
  background-color: $block;
  color: $level-11;
  border: 0;
  width: 100%;
  box-shadow: $block-shadow;

  &__month-container {
    width: 100%;
    padding: 0px;
  }

  &__header {
    background-color: transparent;
    border-bottom: 0;
    padding: 10px 0;
  }

  &__current-month {
    display: none;
  }

  &__header__dropdown {
    padding-bottom: 0 !important;
  }

  &__month {
    margin: 6px 0 8px 0;
  }
}

.react-datepicker-wrapper {
  .form-control {
    padding-left: 40px;
  }
}

.react-datepicker-popper[data-placement^=bottom] {
  width: 100%;
  max-width: 320px;
  z-index: 2;
  padding-top: 4px !important;
}

.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 0;
}

.react-datepicker-popper[data-placement^=bottom],
.react-datepicker-popper[data-placement^=top] {
  .react-datepicker__triangle {
    &:before, &:after {
      content: none;
    }
  }
}

.react-datepicker {
  &__day-name,
  &__day,
  &__time-name {
    color: $level-11;
    font-size: 12px;
    width: 32px;
    line-height: 32px;
    margin: 0 6px 8px;
    border-radius: 50%;
  }

  .react-datepicker__day-names {
    border-bottom: 1px solid $border-input-typed-button-2;
  }

  &__day-name {
    color: $level-8;
    text-transform: uppercase;
    line-height: 1;
    font-weight: $font-weight-semibold;
    margin-bottom: 9px;
  }

  &__day--outside-month {
    color: $level-11;
  }

  &__year-read-view--down-arrow,
  &__month-read-view--down-arrow,
  &__month-year-read-view--down-arrow {
    border-width: 1px 1px 0 0;
    border-color: $level-8;
    top: 0;
    position: absolute;

    &:hover {
      border-color: $level-10;
    }
  }

  &__navigation-icon::before {
    border-width: 1px 1px 0 0;
    border-color: $level-8;
    top: 15px;
  }

  &__day,
  &__month-text,
  &__quarter-text,
  &__year-text {
    &:hover {
      color: $level-1 !important;
      background-color: $level-10;
      border-radius: 50%;
    }
  }

  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--selected,
  &__month-text--in-selecting-range,
  &__month-text--in-range,
  &__quarter-text--selected,
  &__quarter-text--in-selecting-range,
  &__quarter-text--in-range,
  &__year-text--selected,
  &__year-text--in-selecting-range,
  &__year-text--in-range,
  &__day--today {
    color: $level-1 !important;
    background-color: $level-10;
  }

  &__day--disabled {
    color: $level-7;
  }

  &__header__dropdown {
    color: $level-7;
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 9px;
    line-height: 30px;
  }

  &__month-read-view {
    min-width: 100px;
    padding-right: 16px;
    margin-right: 5px;
    visibility: visible !important;
    color: $level-10;
    font-weight: $font-weight-semibold;
  }

  &__year-read-view {
    min-width: 70px;
    padding-right: 16px;
    margin-right: 5px;
    visibility: visible !important;
    color: $level-10;
    font-weight: $font-weight-semibold;
  }

  &__month-read-view--down-arrow,
  &__year-read-view--down-arrow {
    right: 2px;
    top: 6px;
    visibility: visible;
  }

  &__month-read-view--selected-month
  &__year-read-view--selected-year {
    visibility: visible;
  }

  &__year-dropdown,
  &__month-dropdown,
  &__month-year-dropdown {
    background-color: $block;
    border: 0;
    top: 34px;
    box-shadow: $block-shadow-xs;
  }

  &__month-dropdown {
    left: 20%;
    width: 100px;
    max-height: 172px;
    overflow-y: auto;
  }

  &__year-dropdown {
    width: 70px;
    left: 56%;
    max-height:200px;
    overflow-y: auto;
  }

  &__navigation {
    top: 8px;
    &:focus {
      border: 0;
      outline: none;
    }
  }

  &__navigation--years {
    position: relative;
    width: 100%;
    height: 24px;
    top: 0;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border: 1px solid #6c6c6f;
      border-left: 0;
      border-top: 0;
      left: 28px;
      position: absolute;
      top: 4px;
    }

    &.react-datepicker__navigation--years-previous {
      &:after {
        transform: rotate(45deg);
      }
    }

    &.react-datepicker__navigation--years-upcoming {

      &:after {
        transform: rotate(225deg);
        top: 10px;
      }
    }
  }

  &__year-option, &__month-option {
    padding: 2px;
    font-size: 12px;

    &:hover,
    &.react-datepicker__year-option--selected_year,
    &.react-datepicker__month-option--selected_month {
      background-color: $dropdown-list-hover;
      color: $os-link;

      .react-datepicker__navigation--years {
        &:after {
          border-color: $os-link ;
        }
      }
    }
  }

  &__year-option--selected,
  &__month-option--selected,
  &__month-year-option--selected {
    left: 8px;
  }

}
