.notifications-page {
  .os-post-message-block {
    .form-control {
      margin-bottom: 0;
      line-height: 1;
      min-height: 50px;
      padding: 16px 55px 0px 16px;
      overflow: hidden;
    }
  }

  .nf-card-details-row {
    border-bottom: 0;
    padding: 0;
    flex-direction: column;

    + .nf-card-details-row {
        .nf-day-heading {
          padding-top: 32px;
        }
    }
  }

  .nf-card-info {
    background-color: $block;
    border-radius: 12px;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 32px 32px 40px;
    box-shadow: $block-shadow;

    @media screen and (max-width: 767px) {
      padding: 24px;
    }
  }

  .nf-content-block {
    .nf-text-info {
      color: $level-9 !important;
      @extend %body-small-1-2;
    }
  }

  .nf-header {
   + .nf-card-details-row {
        .nf-day-heading {
          top: -32px;

          @media screen and (max-width: 767px) {
            top: -34px;
            margin-left: 0 !important;
          }
        }
      }
  }

  .nf-day-heading {
    padding-top: 0;
    color: $heading;
  }

  .nf-avatar-block {
    flex: 0 0 56px;
    max-width: 56px;
    padding-left: 0;

    .nf-avatar-info {
      padding-left: 0;

      .nf-unread-icon {
        left: -18px;
      }
    }
  }
  .nf-cards-area {
    flex: 0 0 316px;
    max-width: 316px;
  }

  .nf-left + small {
    float: right;
  }
}

.notifications-page  {
  .nf-left {
    position: relative;
    display: flex;
    align-items: center;
    .nf-unread-icon {
      position: absolute;
      width: 8px;
      height: 8px;
      background: $lt-notification-bg;
      left: auto;
      top: calc(50% - 3px);
      border-radius: 6px;

      @media screen and (max-width: 768px) {
        position: relative;
        display: inline-block;
        top: auto;
        width: 20px;
      }
    }
  }
}

.nf-card-details-row {
  display: flex;
  padding: 16px 0;
  border-bottom: solid 1px $border-button-1;
  flex-wrap: wrap;

  small {
    font-weight: normal;

    @media screen and (max-width: 767px) {
      line-height: 28px;
    }
  }

  .nf-card-info {
    flex-wrap: wrap;
    display: flex;
  }
}

.extra-small-min-card {
  .card-v2-header  {
    .caption  ~ .space-user-info {
      padding-right: 40px;
    }
  }
}

.nf-header {
  color: $secondary-heading;
  position: relative;
  z-index: 1;

  + .nf-card-details-row {
      .nf-day-heading {
        position: relative;
        top: -48px;
        padding: 0 !important;
      }
    }
}

.nf-day-heading {
  width: 100%;
  padding: 8px 0 32px;
}

.nf-avatar-block {
  flex: 0 0 88px;
  max-width: 88px;

  .act-avatar-item  + .act-avatar-item  {
    position: relative;
    margin-left: -20px;
  }
}

.nf-action-bar {
  margin-top: 1.8rem;

  &:empty {
    display: none;
  }
}

.nf-avatar-info {
  position: relative;
  padding-left: 20px;
  height: 40px;

  .nf-unread-icon {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $lt-notification-bg;
    left: 5px;
    top: calc(50% - 3px);
    border-radius: 6px;
  }
}

.nf-content-block {
  padding-right: 24px;
  word-break: break-word;
  flex: 1;

  .nf-text-info {
    font-size: 100%;
  }

  .nf-title {
    margin-right: 4px;
    @extend %heading-4-1-2;
    color: $level-11;
    a {
      color: $level-11;

      &:hover {
        color: $level-11;
        text-decoration: underline;
      }
    }

    + small {
      color: $level-9;
      @extend %heading-5;
    }
  }

  pre {
    .a-link {
      font-size : 16px;
    }
  }
}

.nf-cards-area {
  flex: 0 0 240px;
  max-width: 240px;

  .nf-card-view {
    border-radius: 4px;
    display: flex;
    height: 120px;
    width: 100%;
    text-align: center;
    align-content: center;
  }

  .locked-care-card {
    .action-buttons {
      position: static;
      margin-top: 0.5rem;
    }
  }
}

.detail-page {
  .nf-cards-area {
    .extra-small-min-card {
      .os-sm-care {
        max-height: unset;
        height: auto;
        min-height: 96px;
      }
    }
  }
}


.change-password {
  border-radius: 4px;
  background: rgba(34, 34, 38, 0.8);
  display: flex;
  padding-bottom: 12px;
  padding-right: 24px;
  justify-content: space-between;

  svg {
    vertical-align: top;
  }

  .protect-info {
    padding: 24px 34px 12px 23px;
  }

  .action-col {
    padding-top: 48px;
  }

  .change-password-heading {
    line-height: 1;
    padding-bottom: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.custom-fixed-banner {
  position: fixed;
  right: 27px;
  top: 80px;
  z-index: 1022;
  width: 480px;


  .small-password-alert {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    z-index: auto;

    + .small-password-alert {
      margin-top: 16px;
    }
  }
}

.small-password-alert {
  background: $block;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  right: 27px;
  top: 80px;
  z-index: 1051;

  .alert-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.no-touch-support {
  .parsetext-link:hover {
    color: $os-link-hover;
    text-decoration: underline;
  }
}


.ipad {
  &.portrait {
    .change-password {
      padding-right: 16px;
    }
  }
}

@media(max-width: 550px) {
  .custom-fixed-banner {
    right: 10px;
    left: 10px;
    top: 64px;
    width: auto;
  }

  .small-password-alert {
    width: auto;
    right: 10px;
    left: 10px;
    top: 64px;
  }
}

.mobile-device {
  .nf-cards-area {
    display: none;
  }

  .nf-title-bar {
    min-height: 40px;
  }

  .nf-action-bar {
    margin-top: 20px;
  }

  .nf-content-block {
    padding-right: 0;

    .os-post-message-block {
      width: 100%;
      text-align: left;
    }
  }

  .nf-day-heading {
    padding: 16px 0;
  }

  .nf-action-bar {
    display: flex;
    direction: rtl;

    .osbtn {
      margin: 0 0 0 16px;
    }
  }

  &.portrait {
    .nf-container {
      padding-right: 5px;
      padding-left: 5px;
    }

    .nf-day-heading {
      padding: 0 0 24px;
    }

    .change-password {
      margin-right: -20px;
      margin-left: -20px;
    }

    .nf-card-details-row {
      margin-right: -16px;
      margin-left: -16px;
    }

    .nf-card-details-row {
      padding: 16px 20px;
      position: relative;
    }

    .nf-content-block {
      padding-right: 0;

      .nf-action-bar,
      .nf-text-info {
        margin-left: -70px;
        width: calc(100% + 70px);
      }
    }


    .nf-avatar-block {
      max-width: 70px;
      flex: 0 0 70px;

      .nf-action-bar {
        margin-left: -70px;
        width: calc(70px + 70px);
      }
    }

    .nf-avatar-info {
      padding-left: 0;

      .nf-unread-icon {
        left: -12px;
      }
    }


    .change-password {
      padding: 24px 20px;
      position: relative;
      flex-wrap: wrap;

      .svg-icon {
        position: absolute;
        left: 20px;
        width: 116px;
        top: 10px;
        height: 80px;
      }

      .protect-info {
        padding: 0;
        width: 100%;
      }

      .change-password-heading {
        padding-left: 128px;
        min-height: 76px;
        line-height: 1.5;
        margin-bottom: 0;
      }

      .action-col {
        padding-top: 8px;
        text-align: right;
        width: 100%;
      }
    }

    .notifications-page {
      .nf-card-details-row {
        padding: 0;

        .nf-day-heading {
          margin-left: 0;
        }
      }
    }
  }

  &.landscape {
    .change-password {
      position: relative;
      padding-bottom: 48px;
      padding-right: 0;
      padding-left: 20px;

      .image-col {
        padding-top: 4px;
      }

      .change-password-heading {
        margin-bottom: 0;
        padding-bottom: 16px;
      }

      .protect-info {
        padding: 16px 16px 8px;
      }

      .action-col {
        padding: 0;
        position: absolute;
        right: 16px;
        bottom: 24px;
      }
    }
  }
}

.breadcrumb-header {
  padding-top: 32px;
  color: $level-11;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 12px;

  .os-btn-link {
    color: $level-11;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }

  i {
    width: 48px;
    text-align: center;
    min-width: 48px;
    font-size: 16px;
    vertical-align: middle;
    font-weight: normal;
    display: inline-block;
  }
}
