@mixin dragHandle($x) {
  .add-new-section-division,
  .add-new-section {
    height: $x;
    max-height: $x;

    .header-list-icon {
      font-size: $x - 15px;
    }
  }
}

@mixin dragHandlePosition($x, $width: false) {
  .add-new-section-division,
  .add-new-section  {
    left: -$x ;
    ~ .add-new-section-division
      .add-new-section {
        right: -$x;
      }

    @if $width {
      width: $x * 2;
    }
  }
}

@mixin spaceCardCol($x, $y) {
  .space-card-col {
    max-width: calc(#{$x} - #{$y});
    flex-basis: calc(#{$x} - #{$y});
    width: calc(#{$x} - #{$y});
  }
}

@mixin thumbnailSpaecCardCol($x) {
  .col-thumb {
    height: 120px;
    padding: 0 $x;
    min-width: $x * 2 + 120px;
    max-width: $x * 2 + 120px;
    flex: $x * 2 + 120px;
  }
}

@mixin thumbnailSpaecCardCol2($x, $y) {
  .col-thumb {
    height: 120px;
    padding: 0 $x;
    min-width: $y;
    max-width: $y;
    flex: $y;

    [role="div"] {
      text-align: center;

      .os-card-v2 {
        display:  inline-block;
        width: 100%;
        max-width: 120px;
      }
    }
  }
}

@mixin dragHandleForThumbnail($n, $x, $y) {
  .col-thumb {
    &:nth-child(#{$n}n),
    &:last-child {
      .add-new-section-division:last-child,
      .add-new-section:last-child {
        right: -$x;
        left: auto;
      }
    }
    ~ div {
        max-width: $y;
        padding: 0 $x;
      }
  }
}

.folders {
  .no-thumbnail {
    border-radius: 4px;
    background: rgb(117,176,245);
    background: linear-gradient(135deg, rgba(117,176,245,1) 0%, rgba(131,112,177,1) 37%, rgba(178,114,169,1) 61%, rgba(185,132,178,1) 99%);
  }

  .big {
    .no-thumbnail {
      border-radius: 8px 8px 0 0;
      background: rgb(117,176,245);
      background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    }
  }

  .space-links-container {
    .space-card-col {
      margin-bottom: 2rem !important;
    }

    .cs-title {
      padding-bottom: 0 !important;
      color: $level-11;
      font-size: 12px;
    }

    .thumbnail-image  {
      position: relative;
    }

    .ifill-os-play {
      cursor: pointer;
    }

    &.thumbnail {
      @include dragHandle(120px);
      @include dragHandlePosition(12px, $width: true);
      @include thumbnailSpaecCardCol(12px);

      .card--loading {
        height: 120px;
        .card__content {
          min-height: 100%;
        }

        .bar-1 {
          max-height: 8px;
        }
        .bar-2 {
          max-height: 16px;
        }
      }

      @media(min-width: 768px) {
        @include thumbnailSpaecCardCol(15px);
        @include dragHandlePosition(15px, $width: true);
        @include dragHandleForThumbnail(5, 16px, 20%)
      }

      @media(min-width: 1024px) {
        /*@include thumbnailSpaecCardCol(10px);*/
        @include thumbnailSpaecCardCol2(10px, 14.2857%);
        @include dragHandlePosition(10px, $width: true);
        @include dragHandleForThumbnail(7, 10px, 14.2857%)
      }

      @media(min-width: 1100px) {
        @include thumbnailSpaecCardCol2(8px, 11.11%);
        @include dragHandlePosition(10px, $width: true);
        @include dragHandleForThumbnail(9, 8px, 11.11%)
      }

      @media(min-width: 1250px) {
        /*@include thumbnailSpaecCardCol(8px);*/
        @include thumbnailSpaecCardCol2(8px, 10%);
        @include dragHandlePosition(8px, $width: true);
        @include dragHandleForThumbnail(10, 8px, 10%)
      }
    }

    &.extra-small {
      @include dragHandle(96px);
      @include dragHandlePosition(12px, $width: true);

      @media (min-width: 768px) and (max-width: 1023px) {
        .space-card-col {
          @include minmaxwidth(50%)
        }

        .space-card-col:nth-child(2n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media (min-width: 1023px) and (max-width: 1439px) {
        .space-card-col {
          @include minmaxwidth(33.33%)
        }

        .space-card-col:nth-child(3n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media(min-width: 1440px) {
        @include dragHandlePosition(8px, $width: true);
        .space-card-col {
          ~ .space-card-col {
              max-width: 25%;
            }
        }
      }

      .space-card-col:last-child {
        .add-new-section-division:last-child,
        .add-new-section:last-child {
          right: -12px;
          left: auto;
        }
      }
    }

    &.big {
      @include dragHandle(196px);
      @include dragHandlePosition(12px, $width: true);

      .cs-title {
        font-size: 0.75rem;
      }

      .space-card-col:nth-child(5n),
      .space-card-col:last-child {
        .add-new-section-division:last-child,
        .add-new-section:last-child {
          right: -8px;
          left: auto;
        }
      }

      @media (min-width: 768px) and (max-width: 1099px) {
        .space-card-col {
          @include minmaxwidth(33.33%)
        }

        .space-card-col:nth-child(3n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media (min-width: 1100px) and (max-width: 1439px) {
        .space-card-col {
          @include minmaxwidth(25%)
        }

        .space-card-col:nth-child(4n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media(min-width: 1440px) {
        @include dragHandlePosition(8px, $width: true);

        .space-card-col {
          padding: 0 8px;

          ~ .space-card-col {
              max-width: 20%;
            }
        }
      }

      .space-card-col ~ .space-card-col {
        .card__content {
          height: 196px;
        }
      }
    }

    &.list {
      @include spaceCardCol(100%, 0px);
      .cd-list-img {
        position: relative;
      }

      .cd-file-name,
      .cd-extra-info {
        @include text-truncate;
        display: block;
      }

      .table-list {
        position: relative;
        @media screen and (min-width: 768px) {
          margin-bottom: 2px !important;
        }

        @media screen and (min-width: 1440px) {
          padding: 0;

          ~ .space-card-col {
            padding: 0;
          }
        }
      }

      .cards {
        .space-card-col {
          &:first-child {
            .add-new-section-division {
              display: none !important;
            }
          }
        }
      }
    }
  }
}

.spaces, .care_spaces {
  .space-links-container {
    &.small {
      @include dragHandle(160px);
      @include dragHandlePosition(24px, $width: true);

      @media (min-width: 768px) and (max-width: 1023px) {
        .space-card-col {
          @include minmaxwidth(50%)
        }

        .space-card-col:nth-child(2n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media (min-width: 1023px) and (max-width: 1439px) {
        .space-card-col {
          @include minmaxwidth(33.33%)
        }

        .space-card-col:nth-child(3n),
        .space-card-col:last-child {
          .add-new-section-division:last-child,
          .add-new-section:last-child {
            right: -12px;
            left: auto;
          }
        }
      }

      @media(min-width: 1440px) {
        @include dragHandlePosition(12px, $width: true);
        .space-card-col {
          ~ .space-card-col {
              max-width: 33.33%;
            }
        }
      }

      .space-card-col:last-child {
        .add-new-section-division:last-child,
        .add-new-section:last-child {
          right: -12px;
          left: auto;
        }
      }
    }
  }
}

.space-card-col {
  position: relative;
  .add-new-section-division,
  .add-new-section {
    position: absolute;
    top: 0;

    ~ .add-new-section-division,
    ~ .add-new-section {
      left: -12px;
    }
  }

  &.table-list {
    .cs-title {
      font-size: 1rem;
    }

    .add-new-section-division,
    .add-new-section {
      left: 0px;
      width: auto;
      right: 0px;
      height: 24px;
      top: 102%;
      z-index: 1;

      .ifill-os-add-new-section {
        transform: rotate(90deg) translate(-50%, -50%);
        top: -34px;
        left: calc(50% - 72.5px);
      }

      &:first-child {
        top: 0;
      }
    }
    .drag-active {
      .os-card-v2 {
        border-radius: 4px;
      }
    }

    .cd-list-img {
      overflow: hidden;
      .img-parent {
        height: inherit;
      }
    }

    ~ .space-card-col {
        .card--loading {
          .card__content {
            min-height: unset;
          }

          .loading-card-overlay {
            height: 65%;
          }

          .card-top {
            height: 50px;
            padding: 21px 60px 0 16px;
            display: flex;
          }

          .bar {
            height: 12px;
            max-height: 12px;
            margin-bottom: 0;
          }

          .bar-group {
            flex: 1;
            padding-left: 16px;
            display: flex;
            justify-content: space-between;

            .bar {
              margin: 0;
              max-width: calc(50% - 8px);
            }
          }

          .card-body {
            background: none;
            right: 16px;
            position: absolute;

            .loader-btn {
              width: 100%;
              left: auto;
              right: 0;
              height: 13px;
              bottom: auto;
              background: none;
              opacity: 0.5;

              &:before {
                @include osfont;
                content: "\e961";
              }
            }
          }
        }
    }
  }

  ~ .add-new-section {
    display: none;
  }
}

.folders, .drop-active {
  .space-card-col {
     ~ .add-new-section {
      display: inline-block ;
    }
  }
}

.folder-table-block {
  .cd-list-img {
    overflow: hidden;
    .img-parent {
      height: inherit;
    }
  }
}

.thumbnail,
.big {
  .card-dropdown-menu {
    opacity: 0;
  }

  .os-card-v2:hover {
    .card-dropdown-menu{
      opacity: 1;
    }
  }
}

.big {
  .image-icon {
    font-size: 24px;
  }
}

.ipad {
  .thumbnail,
  .big {
    .card-dropdown-menu {
      opacity: 1;
    }
  }

  .thumbnail {
    .header-dropdown-list {
      left: 50%;
      transform: translateX(-50%);

      .header-dropdown-list-item  {
        &:before {
          right: calc(50% - 12px);
          transform: translateX(-50%);
        }
      }
    }
  }
}

.thumbnail {
  .card-dropdown-menu {
    top: 16px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.big {
  .card-dropdown-menu {
    top: 8px;
    right: 8px;
  }
}

.card-z-index {
  &.table-list,
  &.space-card-col {
    z-index: 4;
  }
}

.ipad{
  .table-list {
    margin-bottom: 2px !important;
  }

  &.portrait {
    .space-links-container.thumbnail {
      @media(max-width: 768px) {
        @include thumbnailSpaecCardCol(14px);
      }
    }
  }
}

@media screen and (min-width: 1441px) {
  .folders {
    .space-links-container.big {
      .add-new-section-division,
      .add-new-section {
        .header-list-icon {
          font-size: 130px;
        }
      }
    }
  }
}
