.information-tab {
  background: $level-1;
  padding: 32px 12px;
  margin-bottom: 30px;
  white-space: normal;
  word-break: break-word;
  border-radius: 8px;
  font-weight: 300;
  box-shadow: $block-shadow;

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    height: calc(100% - 32px);
  }

  @media(min-width: 768px) {
    padding: 40px 32px 16px;
  }

  h1, h2 ,h3, h4, h5, h6 {
    color: $level-11;

    strong {
      font-weight: inherit;
    }
  }

  .cmn-heading {
    color: $heading;
  }

  h1,
  .cmn-heading {
    @include fontstyle(20px, 600, 1.4);
    margin: 0 0 8px;
    padding: 0;
    width: 100%;
  }

  strong,
  b {
    color: inherit;
    font-weight: 600;

    &.white-text {
      color: #fff;
    }
  }

  p {
    font-weight: 300;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 32px;
  }

  ul {
    font-weight: 300;
    margin-bottom: 8px;
  }

  ul:not(.header-dropdown-list) {
    padding: 0 0 0 20px;
  }

  h2 {
    text-transform: uppercase;
    width: 100%;
  }

  h2 ,h3, h4, h5, h6 {
    @include fontstyle(16px, 600, 1.4);
  }

  a {
    font-weight: $link-weight;

    &:not(.osbtn):hover {
      text-decoration: underline;
    }

    &.os-card-v2:hover {
      text-decoration: none;
    }
  }

  pre {
    white-space: break-spaces;
  }

  .os-text {
    color: inherit;
  }
}

.os-text {
  color: $level-11;
}

.tool-description-block {
  padding: 40px 40px 32px;
  box-shadow: 0 2px 2px 0 rgba(18, 18, 20, 0.2);
  border-radius: 8px;
}

.release-block-info {
  font-size: 12px;

  .release-block-text {
    padding-top: 16px;
  }

  .release-block-img {
    text-align: center;

    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }
}

.course-video-wrapper, #course-video-wrapper, .clv-row {
  margin-bottom: 40px;
  .lecture-loader {
    z-index: 10;
    height: 48px;
    width: 80px;
    text-align: center;
    border-radius: 8px;
    background: $cl-link;
    cursor: pointer;
    color: #fff;
    box-shadow: 0 2px 7px 0 rgba(18, 18, 20, 0.1);
    left: 50%;
    top: 50%;
    right: unset;
    bottom: unset;
    transform: translate(-50%, -50%);
    display: inline-flex;

    i {
      font-size: 20px;
      margin: auto;
      color: inherit;
    }

    &.hidden {
      display: hidden;
    }

    &:hover {
      background: $os-link-hover;
    }
  }
}

#course-learing-wrapper,
#course-curriculum-wrapper {
  margin-bottom: 40px;

  .cmn-heading {
    margin-bottom: 24px;
  }

  ul {
    padding: 0 0 0 32px;
    list-style: none;
    position: relative;
    margin: 0;

    li {
      color: #d4d4d9;
      line-height: 32px;
      font-size: 20px;

      &:before {
        content: "\e9c1";
        font-family: 'fill-os-icon' !important;
        color: $lt-green;
        left: 0;
        position: absolute;
      }
    }
  }

  .clock-icon-block {
    color: $level-9;
    display: inline-flex;
    align-items: center;
  }

  .ifill-os-clock {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
  }
}

#course-curriculum-wrapper {
  counter-reset: curriculum-counter;

  .curriculum-detail-info {
    background: #222226;
    box-shadow: 0 2px 4px 0 rgba(18, 18, 20, 0.2);
    border-radius: 8px;
    color: $level-11;
    position: relative;
    @include font-text(normal, 1rem, 1.5);
    display: flex;
    justify-content: flex-start;

    + .curriculum-detail-info {
      margin-top: 0.5rem;
    }

    .curriculum-title {
      color: $level-11;
      font-size: 12px;
      padding-bottom: 12px;
      text-transform: uppercase;
      @include flexstyle(flex, center, space-between, row);
    }


    .curriculum-info {
      padding: 1.5rem 1.5rem 2rem 3.5rem;
      width: calc(100% - 160px);
      display: inline-block;
      position: relative;
    }

    .curriculum-title:before {
      counter-increment: curriculum-counter;
      content: counter(curriculum-counter);
      border-radius: 1.75rem;
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      left: 1rem;
      top: 1.125rem;
      text-align: center;
      background: #29292e;
      color: $level-11;
      line-height: 1.75rem;
      font-weight: 600;
    }
  }

  .curriculum-image {
    width: 160px;
    max-height: 100%;
    border-radius: 8px 0 0 8px;
    display: inline-block;


    img {
      width: 100%;
      height: 100%;
      border-radius: 8px 0 0 8px;
      object-fit: cover;
    }
  }
}

@media(min-width: 768px) {
  .release-block-info {
    display: flex;

    .release-block-img {
      min-width: 170px;
      max-width: 170px;
      text-align: left;
      display: inline-block;
    }

    .release-block-text {
      padding-left: 12px;
      padding-top: 0;
    }
  }
}

#course-release-wrapper, #course-skill-wrapper {
  display: none;
}

.ipad {
  .information-tab {
    #course-learing-wrapper,
    #course-curriculum-wrapper {
      margin-bottom: 32px;
      ul {
        padding: 0;
        list-style-position: inside;
      }

      .curriculum-title:before {
        background-color: transparent;
      }
    }
  }

  .release-block {
    .row-col-spac-16 {
      justify-content: space-between;
    }
  }

  &.portrait {
    .information-tab {
      padding-top: 32px;
    }
  }
}

.mobile-device {
  .information-tab {
    padding: 24px 15px;
    white-space: normal;
    word-break: break-word;
    margin-bottom: 40px;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 rgba(18, 18, 20, 0.2);

    p {
      font-size: 16px;
      line-height: 24px;
    }

    &.tool-description-block {
      margin-bottom: 0;
    }

    #about-course-info {
      .cmn-heading {
        display: none;
      }
    }

    #course-sponsors-wrapper,
    #course-testimonial-wrapper {
      margin-bottom: 32px;

      .cmn-heading {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 16px;
      }



      .row-col-space-16 {
        width: unset;
        margin: 0;
        padding: 0 0 5px;
      }
    }

    #course-curriculum-wrapper {
      .cc-heading {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      > .row .col-sm-4 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          margin-bottom: 0.25rem
        }
      }

      ol {
        padding-left: 0 !important;
      }
    }

    .release-block {
      padding-top: 0 !important;
      margin-top: 32px !important;
    }

    .release-block-info {
      padding-top: 15px;
    }
  }

  #course-curriculum-wrapper {
    .curriculum-image {
      width: 100px;
    }

    .curriculum-title {
      font-size: 8px;
      line-height: 16px;
      padding-bottom: 4px;
      &:before {
        position: relative;
        width: auto;
        height: auto;
        left: 0;
        top: 0;
        background-color: transparent;
        line-height: 16px;
        margin-right: 4px;
        color: $level-11;
      }
    }

    .curriculum-detail-info {
      font-size: 12px;
      line-height: 16px;
    }

    .curriculum-info {
      width: calc(100% - 100px);
      padding: 12px 8px 12px 12px;
    }
  }
  #course-learing-wrapper,
  #course-curriculum-wrapper {
    margin-bottom: 32px;

    .cmn-heading {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 16px;
    }

    ul {
      padding: 0 0 0 0;

      li {
        line-height: 24px;
        font-size: 16px;
        &:before {
          position: relative;
          margin-right: 12px;
          top: 2px;
        }
      }
    }
  }
  .release-block-info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 24px;

    .release-block-img {
      min-width: 104px;
      max-width: 104px;
      display: inline-block;
    }
    .release-block-text {
      padding-top: 0;
      padding-left: 8px;
      flex-basis: calc(100% - 104px);
      max-width: calc(100% - 104px)
    }
  }
  &.iphone {
    .information-tab {
      #course-learing-wrapper,
      #course-curriculum-wrapper {
        ol {
          padding: 0 !important;
          list-style-position: inside;
        }
      }
    }
  }
}
#course-video-wrapper {
  > article:empty {
    display: none;
  }
}

.cc-row {
  background: #222226;
  box-shadow: 0 2px 2px 0 rgba(18, 18, 20, 0.2);
  min-height: 120px;
  display: flex;
  counter-reset: cc-counter;
  border-radius: 0 8px 8px 0;

  + .cc-row {
      margin-top: 0.5rem;
    }

  figure {
    width: 160px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 8px 0 0 8px;

    img {
      width: 100%;
      max-width: 100%;
      vertical-align: top;
      display: block;
      height: 100%;
    }
  }

  dl {
    margin: 0;
    flex: 1;
    padding: 24px 24px 24px 56px;
  }

  dt {
    position: relative;
    display: flex;
    justify-content: space-between;
    color: $level-11;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &::before {
      position: absolute;
      counter-increment: curriculum-counter;
      content: counter(curriculum-counter);
      left: 0;

      @media (min-width: 768px) {
        width: 28px;
        height: 28px;
        background: #29292e;
        border-radius: 28px;
        font-size: 16px;
        left: -40px;
        top: -5px;
        line-height: 28px;
        text-align: center;
        color: $level-11;
      }
    }

    div:last-child {
      white-space: nowrap;
    }
  }

  dd {
    padding-top: 12px;
    font-size: 16px;
    color: #fff;
  }

  @media(max-width: 767px) {
    min-height: 88px;

    figure {
      width: 100px;
    }

    dl {
      padding: 12px 8px 12px 12px
    }

    dt {
      font-size: 8px;
      padding-left: 8px;
    }

    dd {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.ipad {
  &.portrait {
    #course-learing-wrapper {
      pading-top:8px !important;

      ul {
        li {
          padding-left: 36px;
        }
      }
    }

    #course-video-wrapper {
      margin-bottom: 32px;
    }
  }
}
